var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero is-primary is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-full-mobile is-7-tablet is-5-desktop"},[_c('form',{staticClass:"box",attrs:{"action":""}},[_c('h1',{staticClass:"title has-text-black has-margin-top-20"},[_vm._v(" Registration ")]),_c('b-field',{attrs:{"label":"Email"}},[_c('b-input',{attrs:{"placeholder":"Your email","type":"email","size":"is-medium","icon":"email","icon-right":"close-circle","icon-right-clickable":""},on:{"icon-right-click":_vm.clearIconClick},model:{value:(_vm.input.email),callback:function ($$v) {_vm.$set(_vm.input, "email", $$v)},expression:"input.email"}})],1),_c('b-field',{attrs:{"label":"Username"}},[_c('b-input',{attrs:{"placeholder":"Your username","type":"username","size":"is-medium","icon":"account","icon-right":"close-circle","icon-right-clickable":""},on:{"icon-right-click":_vm.clearIconClick},model:{value:(_vm.input.username),callback:function ($$v) {_vm.$set(_vm.input, "username", $$v)},expression:"input.username"}})],1),_c('b-field',{attrs:{"label":"Password","type":{
                'is-danger': _vm.isPasswordTooShort
              },"message":[
                {
                  'Password must have at least 6 characters': _vm.isPasswordTooShort
                }
              ]}},[_c('b-input',{attrs:{"icon":"lock","size":"is-medium","type":"password","placeholder":"Your password","password-reveal":""},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}})],1),_c('b-field',{attrs:{"label":"Confirm password","type":{
                'is-danger': _vm.passwordsNotMatching
              },"message":[
                {
                  'Passwords do not match': _vm.passwordsNotMatching
                }
              ]}},[_c('b-input',{attrs:{"icon":"lock","size":"is-medium","type":"password","placeholder":"Confirm your password","password-reveal":""},model:{value:(_vm.input.confirmPassword),callback:function ($$v) {_vm.$set(_vm.input, "confirmPassword", $$v)},expression:"input.confirmPassword"}})],1),_c('b-button',{staticClass:"is-medium",staticStyle:{"margin-top":"30px"},attrs:{"disabled":_vm.isLoading,"type":"is-primary","expanded":true,"outlined":true},on:{"click":_vm.registerUser}},[_vm._v("Register")]),_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('span',{staticClass:"flex-row justify-center has-margin-top-20"},[_vm._v(" Already have account? "),_c('span',{staticClass:"has-text-primary has-margin-left-5"},[_c('router-link',{staticClass:"is-centered",attrs:{"to":"/login"}},[_vm._v("Sign In")])],1)])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }