<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-full-mobile is-7-tablet is-5-desktop">
            <form action class="box">
              <h1 class="title has-text-black has-margin-top-20">
                Registration
              </h1>
              <b-field label="Email">
                <b-input
                  placeholder="Your email"
                  v-model="input.email"
                  type="email"
                  size="is-medium"
                  icon="email"
                  icon-right="close-circle"
                  icon-right-clickable
                  @icon-right-click="clearIconClick"
                ></b-input>
              </b-field>

              <b-field label="Username">
                <b-input
                  placeholder="Your username"
                  v-model="input.username"
                  type="username"
                  size="is-medium"
                  icon="account"
                  icon-right="close-circle"
                  icon-right-clickable
                  @icon-right-click="clearIconClick"
                ></b-input>
              </b-field>

              <b-field
                label="Password"
                :type="{
                  'is-danger': isPasswordTooShort
                }"
                :message="[
                  {
                    'Password must have at least 6 characters': isPasswordTooShort
                  }
                ]"
              >
                <b-input
                  v-model="input.password"
                  icon="lock"
                  size="is-medium"
                  type="password"
                  placeholder="Your password"
                  password-reveal
                ></b-input>
              </b-field>

              <b-field
                label="Confirm password"
                :type="{
                  'is-danger': passwordsNotMatching
                }"
                :message="[
                  {
                    'Passwords do not match': passwordsNotMatching
                  }
                ]"
              >
                <b-input
                  v-model="input.confirmPassword"
                  icon="lock"
                  size="is-medium"
                  type="password"
                  placeholder="Confirm your password"
                  password-reveal
                ></b-input>
              </b-field>

              <b-button
                @click="registerUser"
                :disabled="isLoading"
                type="is-primary"
                class="is-medium"
                style="margin-top: 30px"
                :expanded="true"
                :outlined="true"
                >Register</b-button
              >
              <b-loading
                :is-full-page="true"
                :active.sync="isLoading"
              ></b-loading>
              <span class="flex-row justify-center has-margin-top-20">
                Already have account?
                <span class="has-text-primary has-margin-left-5">
                  <router-link to="/login" class="is-centered"
                    >Sign In</router-link
                  >
                </span>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { httpClient } from '@/api/httpClient.js'

export default {
  name: 'Register',
  data() {
    return {
      input: {
        email: '',
        username: '',
        password: '',
        confirmPassword: ''
      },
      isLoading: false
    }
  },
  methods: {
    registerUser() {
      if (!this.passwordsNotMatching && !this.isPasswordTooShort) {
        this.isLoading = true

        httpClient
          .post('/register', {
            email: this.input.email,
            username: this.input.username,
            password: this.input.password
          })
          .then(() => {
            this.isLoading = false

            this.$buefy.notification.open({
              message:
                'Your account has been successfully created. Check your email to active your account',
              type: 'is-success',
              hasIcon: true,
              duration: 5000
            })

            this.$router.push({ name: 'login' })
          })
          .catch(err => {
            this.isLoading = false

            this.$buefy.snackbar.open({
              message: `Error: ${err.response.data.message}`,
              type: 'is-danger',
              position: 'is-top',
              actionText: 'Retry'
            })
          })
      }
    },
    clearIconClick() {
      this.input.email = ''
    }
  },
  computed: {
    isPasswordTooShort() {
      return this.input.password != '' && this.input.password.length < 6
    },
    passwordsNotMatching() {
      return (
        this.input.confirmPassword != '' &&
        this.input.password !== this.input.confirmPassword
      )
    }
  }
}
</script>

<style scoped></style>
